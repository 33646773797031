body, html {
  background-color: rgba(0,0,0,.1);
  height: 100%;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
}
.single-field{
  min-height: 100px;
  background-color: #fff;
  cursor: pointer;
  font-size: 40px;
}
.winner {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.2);
  left: -50px;
  top: -50px;
}
.winner h1{
  width: 50%;
  margin: auto !important;
  padding: 50px;
  margin-top: 10% !important;
  border-radius: 0 !important;
  opacity:.95
}
.winner h1 button{
font-size: 20px;
font-weight: 700;
margin-top: 10px;
}
  span.headline {
  position: absolute;
  top: 30px;
  left: -75px;
  background-color: #38687d;
  color: white;
  padding: 10px 100px;
  transform: rotate(-45deg);
  border:2px solid white;
  font-weight: 700;
}

.canvas {
  height:900px;
  width: 900px;
}


.canvas .row {
  height: 300px;
}

#reset-match {
  position: fixed;
  top: 100px;
  right: 2%;
  width: 50px;
  height: 50px;
  color: white;
  display: inline-block;
  font-weight: 700;
  cursor: pointer;
  z-index: 999;
}
#next-player {
  position: fixed;
  top: 160px;
  right: 2%;
  width: 50px;
  height: 50px;
  color: white;
  display: inline-block;
  font-weight: 700;
  cursor: pointer;
  z-index: 999;
  text-align: center;
}

.highscore {
  position: fixed;
  top: 260px;
  text-align: center;
  right: 2%;
  width: 50px;
  height: 50px;
  display: inline-block;
  font-weight: 700;
  cursor: pointer;
  z-index: 999;
  text-align: center;
}

@media(max-width:1280px){
  .canvas {
    height:600px;
    width: 600px;
  }
  
  
  .canvas .row {
    height: 200px;
  }
  

}

@media(max-width:992px){
  .canvas {
    height:450px;
    width: 450px;
  }
  
  
  .canvas .row {
    height: 150px;
  }
  
}


@media(max-width:600px){
  .canvas {
    height:300px;
    width: 300px;
  }
  
  
  .canvas .row {
    height: 100px;
  }
  

}